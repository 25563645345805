import moment from "moment";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

import styled from "styled-components";
import { Payment, Transaction } from "../../models/model";
import { statusesData } from "../../utils/statusesList";
import { paymentMethodList } from "../../utils/paymentMethodList";

const Modal = styled.div`
  //padding-top: 25px;
  //padding-left: 50px;
  //padding-right: 20000px;
  //padding-bottom: 25px;
  min-height: 100vh;
  background-color: #f5f5f5;
  width: 100vw;
  //margin-left: 50vw;
  height: 100%;
  flex: 1;
`;

const ModalHeader = styled.div`
  background: #f6f6f6 0% 0% no-repeat padding-box;
  box-shadow: 0px 16px 16px #00000029;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding-top: 21px;
  padding-bottom: 21px;

  padding-left: 30px;
  padding-right: 30px;
  height: 80px;
`;

const HeaderTitle = styled.h1`
  font: normal normal 500 24px/32px Roboto;
  letter-spacing: 0px;
  color: #222222;
`;

const HeaderButton = styled.button`
  border-radius: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 38px;
  padding-left: 16px;
  padding-right: 16px;

  text-align: center;
  font: normal normal 500 16px/21px Roboto;
  letter-spacing: 0px;
  border: 0;
`;

const ModalContainer = styled.div`
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: -16px 0px 16px #00000029;
  opacity: 1;
  display: flex;
  flex-direction: row;
  //flex: 1;
  height: calc(100vh - 80px);
  padding-left: 30px;
  padding-right: 30px;
  overflow-y: scroll;

  ::-webkit-scrollbar {
    background: transparent;

    // display:none;

    width: 0px;
  }

  &::-webkit-scrollbar-track {
    /*  margin-top:0;
      border-bottom-right-radius: 16px;
      background-image: url("https://i.stack.imgur.com/Pwbuz.png");
    background-repeat: repeat-y;
    background-size: contain;
      */
    // margin-top: 54px;
  }
  &::-webkit-scrollbar-thumb {
    background: transparent;
    border-radius: 20px;
    border: 2.5px solid rgba(0, 0, 0, 0);
    border-right: 5px solid rgba(0, 0, 0, 0);

    background-clip: padding-box;
    max-height: 5px;
    transition: all 1s;
  }
`;

const BodyMenu = styled.div`
  /*padding-left: 30px;
	padding-right: 30px;
	max-height: calc(100vh - 80px);*/
  display: flex;
  justify-content: space-between;
  flex: 1;
  flex-direction: column;

  padding-top: 30px;
  padding-bottom: 30px;

  //border-right: 1px solid #f0f0f0;
`;

const MenuItem = styled.div`
  background: #f6f6f6 0% 0% no-repeat padding-box;
  border-radius: 24px;
  padding: 20px;
  margin-bottom: 15px;
  position: relative;

  input {
    outline: none;
  }

  .menu_input_container {
    background: #ffffff 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 3px 6px #00000029;
    border-radius: 19px;
    height: 38px;
    display: flex;
    flex-direction: row;
    //padding: 12px;
    padding-right: 0;
    align-items: center;
    padding-left: 12px;

    img {
      width: 20px;
      height: 20px;
    }

    input {
      flex: 1;
      //::after//margin-top: -12px;
      //margin-bottom: -12px;
      margin: 0;
      background: transparent;
      height: 38px;
      border: 0;
    }

    .menu_search_button {
      background: #28a745 0% 0% no-repeat padding-box;
      border-radius: 19px;
      height: 38px;
      text-align: center;
      font: normal normal 500 13px/18px Roboto;
      letter-spacing: 0px;
      color: #ffffff;
      //margin-top: -12px;
      //margin-bottom: -12px;
      padding-left: 16px;
      padding-right: 16px;
      display: flex;
      align-items: center;
      border: 0;
    }
  }

  .float_right {
    float: right;
  }

  .table_row {
  }

  table {
    width: 100%;
    border-spacing: 16px;
  }

  .menu_padding_b-24 {
    padding-bottom: 24px;
  }

  .menu_padding_b-16 {
    padding-bottom: 16px;
  }

  .menu_padding_t-24 {
    padding-top: 24px;
  }

  .menu_padding_t-16 {
    padding-top: 16px;
  }

  .menu_border_b-gray {
    border-bottom: 1px solid #ebebeb;
  }

  .menu_margin_b-8 {
    margin-bottom: 8px;
  }

  .menu_margin_b-14 {
    margin-bottom: 14px;
  }

  .menu_margin_b-16 {
    margin-bottom: 16px;
  }

  .menu_margin_b-32 {
    margin-bottom: 32px;
  }

  .menu_padding_b-16 {
    padding-bottom: 16px;
  }
  .menu_margin_b-2 {
    margin-bottom: 2px;
  }

  .menu_margin_b-20 {
    margin-bottom: 20px;
  }

  .menu_circle {
    display: flex;
    justify-content: center;
    align-items: center;
    background: #28a745 0% 0% no-repeat padding-box;
    border-radius: 12px;
    width: 24px;
    height: 24px;
  }

  p {
    margin: 0;
  }

  .menu_item_title {
    text-align: left;
    font: normal normal bold 16px/21px Roboto;
    letter-spacing: 0px;
    color: #222222;
  }
  .menu_item_label {
    text-align: left;
    font: normal normal normal 11px/15px Roboto;
    letter-spacing: 0px;
    color: #676767;
    text-transform: uppercase;
  }

  .menu_item_info {
    text-align: left;
    font: normal normal 500 15px/20px Roboto;
    letter-spacing: 0px;
    color: #222222;
  }

  .menu_item_info_bold {
    text-align: left;
    font: normal normal 700 15px/20px Roboto;
    letter-spacing: 0px;
    color: #222222;
  }
  .menu_item_info_regular {
    text-align: left;
    font: normal normal 400 15px/20px Roboto;
    letter-spacing: 0px;
    color: #222222;
  }

  .menu_item_invoice_value {
    font: normal normal bold 15px/20px Roboto;
    letter-spacing: 0px;
    color: #222222;
  }

  .menu_item_status {
    height: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #676767 0% 0% no-repeat padding-box;
    border-radius: 10px;
    font: normal normal 500 10px Roboto;
    letter-spacing: 0.5px;
    color: #ffffff;
    text-transform: uppercase;
    padding-left: 12px;
    padding-right: 12px;
  }

  .menu_item_heavy {
    text-align: right;
    font: normal normal bold 18px/24px Roboto;
    letter-spacing: 0px;
    color: #222222;
  }

  .menu_bottom_fixed_br {
    position: absolute;
    background: #28a745 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 6px 6px #00000029;
    border-radius: 24px 0px;
    bottom: 0;
    right: 0;
    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
    display: flex;
  }

  .menu_top_fixed_tr_checked {
    position: absolute;
    background: #28a745 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 6px 6px #00000029;
    border-radius: 0px 24px;
    top: 0;
    right: 0;

    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
  }

  .menu_top_fixed_tr {
    position: absolute;
    background: #fec12d 0% 0% no-repeat padding-box;
    box-shadow: inset 0px 6px 6px #00000029;
    border-radius: 0px 24px;
    top: 0;
    right: 0;

    align-items: center;
    justify-content: center;
    width: 48px;
    height: 48px;
  }

  .menu_detalhes_status {
    height: 20px;
    background: #28a745 0% 0% no-repeat padding-box;
    border-radius: 10px;
    opacity: 1;
    padding-right: 16px;
    padding-left: 16px;
    text-align: center;
    font: normal normal 500 10px/28px Roboto;
    letter-spacing: 0.5px;
    color: #ffffff;
    text-transform: uppercase;
    display: flex;
    align-items: center;
  }
`;

interface ModalProps {
  setModalVisible(b: boolean): void;
  payment: Payment;
}

export default function ModalScreen({ setModalVisible, payment }: ModalProps) {
  const clientName = payment.transactions[0]
    ? payment.transactions[0].name
    : "-";
  const clientDoc =
    payment.doc && payment.doc.length > 0
      ? payment.doc
      : payment.transactions[0] && payment.transactions[0].doc
      ? payment.transactions[0].doc
      : "";

  const empresa = payment.merchant ? payment.merchant.name : "-";

  const provider = payment.transactions[0]
    ? payment.transactions[0].provider
    : "-";

  const ref = payment.transactions[0] ? payment.transactions[0].ref : "-";

  function StringToLocalDate(stringDate: string) {
    return moment(stringDate).format("DD/MM/YYYY HH:mm");
  }
  function getStatus() {
    /*const STATUS_REGISTERED = 0;
		const STATUS_PROCESSING = 1;
		const STATUS_CANCELED = 2;
		const STATUS_FAILED = 3;
		const STATUS_PENDENT = 4;
		const STATUS_FINISHED = 5;
		const STATUS_CONFLITED = 7;
		const STATUS_CHARGEBACK = 8;
		const STATUS_REJECTED = 9;
		const STATUS_RESTRICTED = 11;*/

    const find = statusesData.find((f) => f.value === payment.status);
    if (find) {
      return find.label;
    }
    return payment.status;
  }

  function getStatusColor() {
    const data = [
      { label: "", value: -1 },
      { label: "#7CB342", value: 0 },
      { label: "#1588D1", value: 1 },
      { label: "#757575", value: 2 },
      { label: "#E53935", value: 3 },
      { label: "#7CB342", value: 4 },
      { label: "#7CB342", value: 5 },
      { label: "#FFBB33", value: 7 },
      { label: "#5E35B1", value: 8 },
      { label: "#757575", value: 9 },
      { label: "#757575", value: 11 },
    ];
    const find = data.find((f) => f.value === payment.status);
    if (find) {
      return find.label;
    }
    return "#757575";
  }

  function StringToCurrency(value: string) {
    return parseFloat(value).toLocaleString("pt-BR", {
      minimumFractionDigits: 2,
      style: "currency",
      currency: "BRL",
    });
  }

  function getType(transaction: Transaction) {
  
   
    const find = paymentMethodList.find(
      (f) => `${f.value}` === `${transaction.type}`
    );

    return find?.label || "-";
  }

  function maskDocument(preValue: string): string {
    let value = preValue;
    value = value.replace(/\D/g, "");
    if (value.length <= 11) {
      value = value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, "$1.$2.$3-$4");
    } else {
      value = value.replace(
        /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/g,
        "$1.$2.$3/$4-$5"
      );
    }
    return value;
  }

  function paymentDeviceName() {
    let pdn = payment.device ? payment.device.name : "-";
    if (pdn.length > 10) {
      pdn = pdn.slice(0, 8) + "...";
    }

    return pdn;
  }

  return (
    <Modal style={{ maxWidth: 768 }}>
      <ModalHeader>
        <HeaderTitle>{"Detalhes da Transação"}</HeaderTitle>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
          }}
        >
          <HeaderButton
            onClick={(e) => {
              e.preventDefault();
              setModalVisible(false);
            }}
            style={{ background: "#28A745", color: "#FFFFFF" }}
          >
            Fechar
          </HeaderButton>
        </div>
      </ModalHeader>

      <ModalContainer>
        <BodyMenu style={{ justifyContent: "flex-start", marginRight: 16 }}>
          <MenuItem>
            <p className="menu_item_title menu_margin_b-8">Cliente</p>

            <div className="menu_margin_b-16">
              <p className="menu_item_label menu_margin_b-2">nome</p>
              <p className="menu_item_info">
                {
                  clientName
                  //payment.name
                }
              </p>
            </div>

            <div
              className={""}
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div>
                <p className="menu_item_label menu_margin_b-8=2">documento</p>
                <p className="menu_item_info">
                  {clientDoc.length > 0 ? maskDocument(clientDoc || "-") : "-"}
                </p>
              </div>
            </div>
          </MenuItem>
          {payment.transactions.length > 0 && (
            <MenuItem>
              <p className="menu_item_title menu_margin_b-8">
                Detalhes das transações
              </p>

              {payment.transactions.map((transaction, index) => {
                return (
                  <div
                    className={`${
                      index !== payment.transactions.length - 1
                        ? "menu_padding_b-24 menu_border_b-gray"
                        : ""
                    } ${index !== 0 ? "menu_padding_t-24" : ""}`}
                  >
                    <div
                      className={"menu_margin_b-16"}
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <p className="menu_item_label menu_margin_b-8=2">
                          Tipo
                        </p>
                        <p className="menu_item_info">{`${getType(
                          transaction
                        )} ${`${transaction.installments ?`(${transaction.installments}x)` :''}`}`}</p>
                      </div>

                      <div>
                        <p className="menu_item_label menu_margin_b-8=2">
                          Código Autorizador
                        </p>
                        <p className="menu_item_info">{`${
                          //relatorio.authorizerCode ||
                          transaction.authorizerCode || "-"
                        }`}</p>
                      </div>

                      <div>
                        <p
                          style={{ textAlign: "right" }}
                          className="menu_item_label menu_margin_b-8=2"
                        >
                          Valor
                        </p>
                        <p
                          style={{ textAlign: "right" }}
                          className="menu_item_info"
                        >{`${StringToCurrency(transaction.invoice)}`}</p>
                      </div>
                    </div>

                    <div
                      className={"menu_margin_b-16"}
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <p className="menu_item_label menu_margin_b-8=2">PAN</p>
                        <p className="menu_item_info">
                          {
                            //relatorio.pan ||
                            transaction.pan || "-"
                          }
                        </p>
                      </div>

                      <div>
                        <p className="menu_item_label menu_margin_b-8=2">NSU</p>
                        <p className="menu_item_info">
                          {
                            //relatorio.atk ||
                            transaction.atk || "-"
                          }
                        </p>
                      </div>
                    </div>

                    <div
                      className={""}
                      style={{
                        flexDirection: "row",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div>
                        <p className="menu_item_label menu_margin_b-8=2">itk</p>
                        <p className="menu_item_info">
                          {
                            //relatorio.itk ||
                            transaction.itk || "-"
                          }
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </MenuItem>
          )}
        </BodyMenu>

        <BodyMenu style={{ justifyContent: "flex-start" }}>
          <MenuItem style={{}}>
            <div
              className="menu_margin_b-8"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <p className="menu_item_title">Detalhes do pagamento</p>

              <div
                style={{ background: getStatusColor() }}
                className="menu_detalhes_status"
              >
                {getStatus()}
              </div>
            </div>
            <div className="menu_padding_b-16 menu_border_b-gray">
              <div
                className="menu_margin_b-14"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="menu_item_label menu_margin_b-2">Tipo</p>

                <p className="menu_item_info">
                  {
                    //getType()
                    "Fatura"
                  }
                </p>
              </div>
              <div
                className="menu_margin_b-14"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="menu_item_label menu_margin_b-2">Empresa</p>

                <p className="menu_item_info">
                  {
                    //relatorio.merchant?.name ||
                    empresa || "-"
                  }
                </p>
              </div>
              <div
                className="menu_margin_b-14"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="menu_item_label menu_margin_b-2">Total Pago</p>

                <p className="menu_item_info">
                  {StringToCurrency(payment.total) || "-"}
                </p>
              </div>
              <div
                className="menu_margin_b-14"
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="menu_item_label menu_margin_b-2">Criado em</p>

                <p className="menu_item_info">
                  {StringToLocalDate(payment.created)}
                </p>
              </div>
              <div
                className=""
                style={{
                  flexDirection: "row",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="menu_item_label menu_margin_b-2">Identificador</p>

                <p style={{ textAlign: "right" }} className="menu_item_info">
                  {
                    //payment.ref||
                    ref || "-"
                  }
                </p>
              </div>
            </div>
            <div className="menu_padding_t-16">
              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <p className="menu_item_label">Opções</p>
                <p className="menu_item_label">Subtotal</p>
              </div>

              {payment.invoices.map((invoice) => {
                return (
                  <div
                    className="menu_margin_b-16"
                    key={invoice.id}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <p className="menu_item_invoice_value">
                      {payment.type &&
                      payment.type === "product" &&
                      invoice.product &&
                      invoice.product.name
                        ? invoice.product.name
                        : invoice.contentRefDate
                        ? invoice.contentRefDate
                        : "-"}
                    </p>
                    <p className="menu_item_invoice_value">
                      {StringToCurrency(`${invoice.total}`)}
                    </p>
                  </div>
                );
              })}
            </div>

            <div
              className={"menu_margin_b-14"}
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                //justifyContent: 'space-between',
              }}
            >
              <div style={{ marginRight: 40 }}>
                <p className="menu_item_label menu_margin_b-8=2">Dispositivo</p>
                <p className="menu_item_info">
                  {payment.device ? (
                    payment.device.recipient ? (
                      <span>
                        {payment.device.recipient.name} (
                        {payment.device.name.length > 10 ? (
                          <OverlayTrigger
                            overlay={
                              <Tooltip id="tool-tip">
                                <span style={{ textTransform: "capitalize" }}>
                                  {payment.device?.name || "-"}
                                </span>
                              </Tooltip>
                            }
                          >
                            <span> {paymentDeviceName()}</span>
                          </OverlayTrigger>
                        ) : (
                          paymentDeviceName()
                        )}
                        )
                      </span>
                    ) : (
                      <OverlayTrigger
                        overlay={
                          <Tooltip id="tool-tip">
                            <span style={{ textTransform: "capitalize" }}>
                              {payment.device?.name || "-"}
                            </span>
                          </Tooltip>
                        }
                      >
                        <span> {paymentDeviceName()}</span>
                      </OverlayTrigger>
                    )
                  ) : (
                    "-"
                  )}
                </p>
              </div>

              <div>
                <p className="menu_item_label menu_margin_b-8=2">Provedor</p>
                <p className="menu_item_info">{provider || "-"}</p>
              </div>
            </div>

            <div
              className={""}
              style={{
                flexDirection: "row",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>
                <p className="menu_item_label menu_margin_b-8=2">
                  Identificador do pagamento
                </p>
                <p className="menu_item_info">{payment.id}</p>
              </div>
            </div>
          </MenuItem>

          <div style={{ padding: 15 }} />
        </BodyMenu>
      </ModalContainer>
    </Modal>
  );
}
