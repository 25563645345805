import React, { useState } from 'react';

import * as S from './style';

//import ArrowDownDark from '../../assets/images/arrow_dropdown-dark.svg';
import AccountGray from '../../assets/images/account_filled-gray.svg';
import LogOutGray from '../../assets/images/logout-gray.svg';
import DropdownMenu from 'react-bootstrap/esm/DropdownMenu';
import { Dropdown } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import api from '../../services/api';
import { useRef } from 'react';

export default function HeaderUserButton() {
	const history = useHistory();

	const [user, setUser] = useState('');
	const [email, setEmail] = useState('');

	// eslint-disable-next-line @typescript-eslint/no-unused-vars
	const [pressed, setPressed] = useState(false);
	const dropdownRef = useRef<any>(null);

	function handleClickOutside(e: any) {
		if (
			dropdownRef.current &&
			dropdownRef.current !== null &&
			!dropdownRef.current.contains(e.target)
		) {
			setPressed(false);
		}
	}
	useOutsideAlert(dropdownRef, handleClickOutside);

	useEffect(() => {
		const login = localStorage.getItem('@bill-login');
		const email = localStorage.getItem('@bill-email');

		if (login) {
			const l = JSON.parse(login);
			if (l && l.login) setUser(l.login);
			if (l && l.email) setEmail(l.email);
		} else {
			getUserData();
		}
	}, []);

	async function getUserData() {
		const token = sessionStorage.getItem('@bill-token');
		const localToken = localStorage.getItem('@bill-token');

		const config = {
			headers: {
				Authorization: `Bearer ${token || localToken}`,
			},
		};

		try {
			const response = await api.get('/admin/api/current', config);
			// console.log(response.data);
			if (response.data.user) {
				//setLogin(response.data.login);

				if (response.data.user.login) {
					localStorage.setItem('@bill-login', JSON.stringify(response.data.user));
					setUser(response.data.user.login);
				}
				if (response.data.user.email) {
					localStorage.setItem('@bill-email', JSON.stringify(response.data.user));
					setUser(response.data.user.email);
				}
			}
		} catch (error: any) {
			console.error(error);
		}
	}

	if (user.length > 0)
		return (
			<S.MenuContainer>
				<S.HeaderUserContainer>
					<Dropdown>
						<Dropdown.Toggle className='dropdown-toggle-categoria'>
							<div className='header_user_flex'>
								<span className='user_circle'>{user.substr(0, 2)}</span>
								{
									//<img alt='v' src={ArrowDownDark} />
								}
							</div>
						</Dropdown.Toggle>
						<DropdownMenu>
							<div className='pt-24 pl-24 pb-24'>
								<div className='row-center'>
									<span className='dropdown_circle'>{user.substr(0, 2)}</span>
									<div className='user-info'>
										<p className='user-title'>{user}</p>
										<p className='user-email'>
											{email.length > 0 ? email : '-'}
										</p>
									</div>
								</div>
							</div>

							<div
								onClick={(e) => {
									e.preventDefault();
									history.push('/usuarios');
								}}
								className='row-center user-item-select pl-24'>
								<img
									style={{ width: 20, height: 20 }}
									alt='v'
									src={AccountGray}
								/>
								<p className='select-label'>Meu perfil</p>
							</div>
							<div
								onClick={(e) => {
									e.preventDefault();
									history.push('/login');
								}}
								className='row-center user-item-select pl-24'>
								<img
									style={{ width: 20, height: 20 }}
									alt='v'
									src={LogOutGray}
								/>
								<p className='select-label'>Sair</p>
							</div>

							{/*<Dropdown.Item
							onSelect={(e) => {
								history.push('/login');
							}}>
							Log out
						</Dropdown.Item>*/}
						</DropdownMenu>
					</Dropdown>
				</S.HeaderUserContainer>
			</S.MenuContainer>
		);

	return null;
}

function useOutsideAlert(ref: any, handleClickOutside: any) {
	useEffect(() => {
		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			// Unbind the event listener on clean up
			document.removeEventListener('mousedown', handleClickOutside);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [ref]);
}
