
export const statusesData = [
    { label: '', value: -1 },
    { label: 'Registrado', value: 0 },
    { label: 'Processando', value: 1 },
    { label: 'Cancelado', value: 2 },
    { label: 'Falha', value: 3 },
    { label: 'Pendente', value: 4 },
    { label: 'Completo', value: 5 },
    { label: 'Em conflito', value: 7 },
    { label: 'CHARGEBACK', value: 8 },
    { label: 'Rejeitado', value: 9 },
    { label: 'Em aguardo', value:10 },
    { label: 'Restrito', value: 11 },
    { label: 'Pago em excesso', value: 12 },
];

export const situacoesStatuses = [
    //{ value: '', label: '' },
    { value: 0, label: 'Registrado' },
    { value: 1, label: 'Processando' },
    { value: 2, label: 'Cancelado' },
    { value: 3, label: 'Falho' },
    { value: 4, label: 'Pendente' },
    { value: 5, label: 'Completo' },
    { value: 7, label: 'Em conflito' },
    { value: 8, label: 'Chargeback' },
    { value: 9, label: 'Rejeitado' },
    { label: 'Em aguardo', value:10 },
    { label: 'Restrito', value: 11 },
    { label: 'Pago em excesso', value: 12 },
];

export const transactionStatuses = [
    { label: '', value: -1 },
    { label: 'Processando', value: 0 },
    { label: 'Pago', value: 1 },
    { label: 'Cancelado', value: 2 },
    { label: 'Falha', value: 3 },
    { label: 'CHARGEBACK', value: 4 },
    { label: 'Pendente', value: 5 },
    { label: 'Pago em excesso', value: 12 },

];